<template>
  <v-card class="py-5">
    <v-card-title class="justify-center text-h4 pb-10 primary--text"
      >{{ isUpdate ? 'แก้ไข' : 'สร้าง' }}หมวดหมู่รายจ่าย</v-card-title
    >
    <v-card-text class="popup-background">
      <v-row justify="center" class="mt-1">
        <v-col cols="6">
          <v-select
            label="หมวดหมู่หลัก"
            :items="category_list"
            v-model="form.category_id"
            item-value="value"
            item-text="text"
            outlined
            dense
            background-color="white"
            name="category_id"
          >
          </v-select>

          <v-text-field
            item-value="value"
            item-text="name"
            label="ชื่อหมวดหมู่ย่อย"
            outlined
            dense
            background-color="white"
            v-model="form.name"
            name="name"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="py-5">
      <v-spacer />
      <v-btn outlined class="px-5 mr-10" color="grey" @click="close()">
        ปิด
      </v-btn>
      <v-btn
        class="px-10 mr-15"
        color="primary"
        @click="save()"
        :loading="loading"
        :disabled="loading"
      >
        บันทึกข้อมูล
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    id: {
      type: String,
      default: null
    }
  },
  watch: {
    id: function(newVal, oldVal) {
      this.getData(newVal);
    }
  },
  computed: {
    isUpdate() {
      return this.id;
    }
  },
  data() {
    return {
      loading: false,
      form: {
        branch_id: '',
        expense_category_id: '',
        category_id: '',
        name: '',
        tag: ''
      },
      category_list: [
        { text: 'พรบ.', value: 'PC1' },
        { text: 'ประกันภัย', value: 'PC2' },
        { text: 'ขนส่ง', value: 'PC3' },
        { text: 'ทั่วไป', value: 'PC4' },
        { text: 'ค่าใช้จ่ายบริษัท', value: 'PC5' }
      ],
      loading: false
    };
  },
  created() {
    this.form.category_id = 'PC1';
    this.form.branch_id = this.$store.state.selected_branch._id;
    this.getData();
    if (this.isUpdate) this.getData(this.id);
  },
  methods: {
    save() {
      this.loading = true;
      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
        async result => {
          if (result.isConfirmed) {
            let subPath = 'create_category';
            if (this.isUpdate) {
              subPath = 'edit_expense_category';
              this.form = { expense_category_id: this.id, ...this.form };
              // delete this.form._id
            }
            let body = {
              token: this.$jwt.sign(this.form, this.$privateKey, {
                noTimestamp: true
              })
            };
            await this.$axios
              .post(`${this.$baseUrl}/expense_category/${subPath}`, body)
              .then(async res => {
                this.$alertSuccess({ title: res.status });
              })
              .catch(err => {
                this.$alertServerError({ title: err.error_message });
              });
            this.$emit('onSave');
          }
          this.loading = false;
        }
      );
    },
    close() {
      this.$emit('onClose');
    },
    async getData() {
      if (this.id) {
        let body = {
          token: this.$jwt.sign(
            { expense_category_id: this.id },
            this.$privateKey,
            { noTimestamp: true }
          )
        };
        await this.$axios
          .post(`${this.$baseUrl}/expense_category/get_by_id`, body)
          .then(async res => {
            this.form = { ...res.result };
            this.category_id = res.result.category_id;
          })
          .catch(err => {
            console.log('err', err);
          });
      } else {
        this.form = {
          branch_id: this.$store.state.selected_branch._id,
          expense_category_id: 0,
          category_id: '',
          name: '',
          tag: ''
        };
      }
      this.loading = false;
    }
  }
};
</script>
